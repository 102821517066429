<template>
  <div class="tw-form-control">
    <textarea
      v-if="type === 'area'"
      class="tw-textarea tw-textarea-bordered tw-text-base tw-border-2 lg:tw-text-lg tw-px-3 lg:tw-px-5 tw-rounded-none focus-within:tw-border-0 focus-within:tw-outline-offset-0 focus-within:tw-outline-primary"
      :class="[inputClass,
        {
          'tw-border-error': _error,
        },
      ]"
      ref="inputRef"
      :type="_type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :disabled="disabled"
      v-model.trim="model"
      :inputmode="inputModel"
      @blur="onblur"
      rows="7"
    ></textarea>
    <div
      v-else
      class="tw-input tw-input-lg md:tw-input-xl lg:tw-input-2xl tw-border-2 tw-px-3 lg:tw-px-5 tw-input-bordered tw-flex tw-items-center tw-rounded-none focus-within:tw-border-0 focus-within:tw-outline-offset-0 focus-within:tw-outline-primary"
      :class="[
        {
          'tw-border-error': _error,
        },
      ]"
    >
      <slot name="prepend-inner" />
      <input
        v-if="imask"
        invalid
        ref="inputRef"
        class="tw-grow tw-w-full"
        :disabled="disabled"
        :readonly="readonly || loading"
        :type="_type"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :value="model"
        :inputmode="inputModel"
        v-imask="imask"
        @blur="onblur"
        @focus="onfocus"
        @accept="onAccept"
        @complete="onComplete"
      />
      <input
        v-else
        invalid
        ref="inputRef"
        class="tw-grow tw-w-full"
        :disabled="disabled"
        :readonly="readonly || loading"
        :type="_type"
        :placeholder="placeholder"
        :maxlength="maxlength"
        v-model.trim="model"
        :min="min"
        :max="max"
        :inputmode="inputModel"
        v-imask="imask"
        @blur="onblur"
        @focus="onfocus"
      />
      <span v-if="loading" class="tw-loading tw-loading-spinner tw-loading-xs" />
      <slot v-else name="append-inner" />
    </div>

    <slot name="errorTip" v-if="$slots.errorTip" :message="_error" />

    <div v-else-if="_error && props.showError" class="tw-label tw-w-full tw-flex tw-items-center tw-py-2 tw-px-2">
      <span class="tw-label-text-alt tw-text-error">{{ _error }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
  const props = withDefaults(
    defineProps<{
      label?: string
      placeholder?: string
      type?: 'text' | 'number' | 'email' | 'search' | 'tel' | 'url' | 'area' | 'date'
      errorMessages?: (string | undefined | Ref<string>)[]
      size?: 'lg' | 'xl' | '2xl'
      maxlength?: number
      disabled?: boolean
      loading?: boolean
      readonly?: boolean
      inputModel?: 'text' | 'email' | 'search' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal'
      imask?: Record<string, any>
      min?: string
      max?: string
      showError?: boolean
      inputClass?:string
    }>(),
    {
      type: 'text',
      loading: false,
      inputClass: '',
      theme: 'none',
      size: 'lg',
      inputModel: 'text',
      showError: true,
    },
  )
  const inputRef = ref(null)
  const _type = ref(props.type)
  const _error = computed(() => (props.errorMessages?.filter(Boolean).length ? props.errorMessages[0] : ''))

  const model = defineModel<string | number>()

  const emit = defineEmits(['blur', 'focus'])

  const onblur = () => {
    emit('blur')
  }
  const onfocus = () => {
    emit('focus')
  }

  const onAccept = (e: any) => {
    const maskRef = e.detail
    model.value = maskRef.unmaskedValue
    console.log('accept', maskRef.value)
  }
  const onComplete = (e: any) => {
    const maskRef = e.detail
    model.value = maskRef.unmaskedValue
    console.log('complete', maskRef.unmaskedValue)
  }
</script>
